import React from "react"

import Layout from '../components/layout';
import Navbar from '../components/navbar'
import Footer from '../components/footer'
import FreeTrial from "../components/free-trial";
import SEO from "../components/seo";

import LeftFeature from '../components/left-feature';
import RightFeature from '../components/right-feature';
import FeaturePararaph from '../components/feature-paragraph'
import {AbaxElectricalTestimonial} from '../components/testimonial';
import AppStoreIcons from '../components/app-store-icons';

import employeeTimesheetSrc from '../images/employee-timesheets.svg';
import tabletMultipleEmployeesSrc from '../images/kiosk-keypad.png';
import mobileGpsSrc from '../images/geofence.png';
import setupWizardSrc from '../images/setup-wizard.svg';
import flexibilitySrc from '../images/flexibility.svg';

import ListItem from '../components/list-item';
import FreeTrialButton from '../components/free-trial-button';

const HowItWorks = () => (
	<Layout>
		<SEO 
    		title="How It Works"
    		description="TimeKeeper allows employees to clock in via a mobile app or time clock kiosk (tablet) with optional photos, facial recognition, GPS, geofences and much more."
    	 />
		<Navbar />
		<div className="bg-gray-50">
		  <div className="relative bg-white overflow-hidden">
			  <div className="relative pt-6 pb-16 md:pb-20 lg:pb-24 xl:pb-32">
			    <div className="mt-8 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-20 xl:mt-24">
			      <div className="lg:grid lg:grid-cols-12 lg:gap-8">
			        <div className="sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left">
			          <h2 className="mt-1 text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:leading-none sm:text-6xl lg:text-5xl xl:text-6xl">
			            Time and Attendance
			            <br />
			            <span className="text-highlight-600">simplified</span>
			          </h2>
			          <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
			            TimeKeeper is designed to be simple enough for employees to use, yet flexible enough to suit the needs of most small businesses. 
			            <br className="hidden md:inline" />
			          </p>
			          <p className="mt-2 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
			           See below for some common use cases.
			          </p>
			          <FreeTrialButton />
			        </div>
			       
			        <div className="mt-12 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center">
			          <div className="relative mx-auto w-full rounded-lg lg:max-w-md">
			            <button className="relative block w-full rounded-lg overflow-hidden focus:outline-none focus:shadow-outline">
			              <img className="w-full" src={setupWizardSrc} alt="TimeKeeper How It Works" />
			            </button>
			          </div>
			        </div>
			      </div>
			    </div>
			  </div>
			</div>
			<div className="py-8 bg-gray-50 overflow-hidden lg:py-24">
			  	<div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-screen-xl">
					<RightFeature 
				    	sectionTitle="Multiple Employee Access" 
				    	title="Time Clock Kiosk" 
				    	body={
				    		<div>
					    		<FeaturePararaph>
						          If you'd like one device for multiple employees to clock in from, you can use TimeKeeper Kiosk.
						        </FeaturePararaph>
						        <FeaturePararaph>
						          Kiosk is typically run on a tablet fixed to a wall. Each employee is assigned a unique 4 digit pin that they can use in Kiosk mode to clock in and out.
						        </FeaturePararaph>
						        <AppStoreIcons />
						    </div>
				    	}
				    	imgSrc={tabletMultipleEmployeesSrc}
				    	imgAlt="Tablet for Multiple Employees to Clock In Via"
				    	 />
				    <LeftFeature 
				    	sectionTitle="Individual Employee Access" 
				    	title="Mobile GPS Time Tracking" 
				    	body={
				    		<div>
					    		<FeaturePararaph>
						          Employees can be enabled to use TimeKeeper on their iOS or Android mobile through their own employee account. GPS is required for 
						          clocking in on mobile and geofences can be set up.
						        </FeaturePararaph>
						        <AppStoreIcons />
						    </div>
				    	}
				    	imgSrc={mobileGpsSrc}
				    	imgAlt="GPS Location on Mobile App Clock In"
				    	 />
				    <RightFeature 
				    	sectionTitle="Supports Web, Tablet and Mobile" 
				    	title="Time and Attendance Anywhere" 
				    	body={
				    		<div>
					    		<FeaturePararaph>
						          Administrative staff can access the web portal 24/7 to run real-time timesheets, manage employee time entries, manage employee leave and more.
						        </FeaturePararaph>
						        <FeaturePararaph>
						         Meanwhile, employees can access the web portal (or app) to check their weekly timesheets, review their remaining leave etc. so you don't get the
						         same questions week in, week out.
						        </FeaturePararaph>
						    </div>
				    	}
				    	imgSrc={employeeTimesheetSrc}
				    	includeShadow={true}
				    	imgAlt="Syncing employee timesheets"
				    	 />
				     <LeftFeature 
				    	sectionTitle="Power in Flexibility" 
				    	title="Customise to suit your business" 
				    	body={
				    		<div>
					    		<FeaturePararaph>
						          TimeKeeper can be tweaked to fit the needs of your business by simply clicking a few buttons. Some examples below:
						        </FeaturePararaph>
						        <div className="mt-8">
							        <ListItem
							          	title="Optional Photo and Facial Recognition on Entry"
							          	description="Enable whether you want to take a photo on entry and if you want to apply facial recognition."
							           	icon={
							           		<svg fill="currentColor" viewBox="0 0 20 20" className="w-8 h-8"><path fillRule="evenodd" d="M4 5a2 2 0 00-2 2v8a2 2 0 002 2h12a2 2 0 002-2V7a2 2 0 00-2-2h-1.586a1 1 0 01-.707-.293l-1.121-1.121A2 2 0 0011.172 3H8.828a2 2 0 00-1.414.586L6.293 4.707A1 1 0 015.586 5H4zm6 9a3 3 0 100-6 3 3 0 000 6z" clipRule="evenodd"></path></svg>
							           	}
							           />
							           <ListItem
							          	title="Require Tracking Time on Jobs"
							          	description="Ensure that all time worked by employees is tracked against a particular job."
							          	icon={
							           		<svg fill="currentColor" viewBox="0 0 20 20" className="w-8 h-8"><path d="M9 2a1 1 0 000 2h2a1 1 0 100-2H9z"></path><path fillRule="evenodd" d="M4 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v11a2 2 0 01-2 2H6a2 2 0 01-2-2V5zm9.707 5.707a1 1 0 00-1.414-1.414L9 12.586l-1.293-1.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path></svg>
							           	}
							           />
							           <ListItem
							          	title="Delegate Time Off Approval"
							          	description="Enable managers to approve time off for the employees they are responsible for."
							          	icon={
							           		<svg fill="currentColor" viewBox="0 0 20 20" className="w-8 h-8"><path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd"></path></svg>
							           	}
							           />
							           <ListItem
							          	title="Configurable Leave Types"
							          	description="Add your own leave types that are paid, unpaid, deductible, etc. that we will track for you."
							          	icon={
							           		<svg fill="currentColor" viewBox="0 0 20 20" className="w-8 h-8"><path d="M5 4a1 1 0 00-2 0v7.268a2 2 0 000 3.464V16a1 1 0 102 0v-1.268a2 2 0 000-3.464V4zM11 4a1 1 0 10-2 0v1.268a2 2 0 000 3.464V16a1 1 0 102 0V8.732a2 2 0 000-3.464V4zM16 3a1 1 0 011 1v7.268a2 2 0 010 3.464V16a1 1 0 11-2 0v-1.268a2 2 0 010-3.464V4a1 1 0 011-1z"></path></svg>
							           	}
							           />
							           <ListItem
							          	title="Enable Manual Timesheets"
							          	description="You can enable employees to submit their own timesheets whenever they want via the app."
							          	icon={
							          		<svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10"></path></svg>
							           	}
							           />
							           <ListItem
							          	title="Supports Shift and Fixed Working Patterns"
							          	description="TimeKeeper is flexible to work with shift and fixed working pattern employees."
							          	icon={
							           		<svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" /></svg>
							           	}
							           />
							           <ListItem
							          	title="Rounding Rules"
							          	description="Ensure all times are rounded according to your company policy."
							          	icon={
							           		<svg fill="currentColor" viewBox="0 0 20 20" className="w-8 h-8"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z" clipRule="evenodd"></path></svg>
							           	}
							           />
							    </div>
						    </div>
				    	}
				    	imgSrc={flexibilitySrc}
				    	imgAlt="Flexibility to tune to each business"
				    	 />
				</div>
				<AbaxElectricalTestimonial />
				<FreeTrial />
			</div>
		</div>
		<Footer/>
	</Layout>
)

export default HowItWorks;